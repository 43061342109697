<template>
  <div class="relative overflow-hidden"
       :class="[shapes === 1 ? 'pb-[25vw] md:pb-[15vw] pt-[44vw] md:pt-[8vw]' : 'pb-[8vw] md:pb-[10vw] pt-[45vw] md:pt-[11vw]']"
  >
    <AnimateFadeIn>
      <div class="text-[14vw] md:text-[5vw] text-indigo leading-[14vw] md:leading-[5vw] font-work font-extrabold text-center">
        {{ title }}
      </div>

      <slot />
    </AnimateFadeIn>
    <div ref="roulette"
         class="relative h-[165vw] md:h-[53vw] overflow-hidden"
    >
      <div v-if="slider"
           class="absolute bottom-[27vw] md:bottom-[9vw] z-10 flex justify-center w-full space-x-[2vw]"
      >
        <SectionRouletteNavigation @prev="handlePrev"
                                   @next="handleNext"
        />
      </div>
      <div class="absolute flex justify-center translate-y-[375vw] md:translate-y-[127vw] inset-0">
        <div class="relative h-[98.5vw] w-[55.5vw] md:h-[31vw] md:w-[18.75vw] bg-red">
          <div class="keen-slider !overflow-visible w-full absolute h-full z-10 cursor-grab will-change-auto"
               ref="sliderRef"
          >
            <div class="absolute text-white transition duration-300 ease-in-out will-change carousel__cell"
                 v-for="(card, index) in cards"
                 :key="index"
            >
              <CardCharacter :title="card.title"
                             :character="card.character"
                             :background-color="card.color"
                             :image="card.image"
                             type="roulette"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <SectionRouletteShapes1 v-if="shapes === 1" />
    <SectionRouletteShapes2 v-if="shapes === 2" />
  </div>
</template>

<script setup lang="ts">
import { useNuxtApp } from '#imports';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/vue.es';
import { inView } from 'motion';
import { onMounted, ref } from 'vue';
import { screenSize } from '~/composables/screen-size';

type RouletteProps = {
  title: string;
  exclude?: false | string;
  shapes?: number;
}

const { $event } = useNuxtApp();
const props = withDefaults(defineProps<RouletteProps>(), {
  shapes: 1,
  exclude: false
});

const size = screenSize();
const roulette = ref(null);

const carousel = slider => {
  const modifier = -6.5; // Rotation circle size
  const cardWidth = size.value.width > 768 ? 18.75 : 55.5;

  const y = (size.value.width / 100) * cardWidth * modifier;

  function rotate() {
    const deg = 360 * slider.track.details.progress;
    slider.container.style.transform = `rotate(${-deg}deg) translate3d(0, 0, 0)`;
  }

  slider.on('created', () => {
    const deg = 360 / slider.slides.length;
    slider.slides.forEach((element, idx) => {
      element.style.transform = `rotate(${deg * idx}deg) translate3d(0, ${y}px, 0)`;
    });
    rotate();
    slider.moveToIdx(6);
  });

  slider.on('detailsChanged', rotate);
};

const [sliderRef, slider] = useKeenSlider(
  {
    loop: true,
    selector: '.carousel__cell',
    renderMode: 'custom',
    mode: 'free-snap'
  },
  [carousel]
);

onMounted(async () => {
  inView(roulette.value, info => {
    slider.value.moveToIdx(12, true, { duration: 7000 });
  }, { amount: 0.4 });

  // add event listener on mouse hover on slider child elements, only on desktop
  if (size.value.width >= 768 ) {
    const elements = sliderRef.value?.querySelectorAll('.carousel__cell');
    const translateAmount = size.value.width / 100 * 1.5;

    if (elements) {
      elements.forEach(element => {
        element.addEventListener('mouseenter', () => {
          // element.classList.add('will-change');

          const transform = element.style.transform;
          const translateY = transform.match(/translate3d\((.*),(.*)px,(.*)\)/)?.[2];
          const newTranslateY = translateY ? parseFloat(translateY) - translateAmount : translateAmount;

          element.style.transform = transform.replace(/translate3d\((.*),(.*)px,(.*)\)/, `translate3d($1,${newTranslateY}px,$3)`);
        });


        element.addEventListener('mouseleave', () => {
          // element.classList.remove('will-change');
          const transform = element.style.transform;
          const translateY = transform.match(/translate3d\((.*),(.*)px,(.*)\)/)?.[2];
          const newTranslateY = translateY ? parseFloat(translateY) + translateAmount : 0;

          element.style.transform = transform.replace(/translate3d\((.*),(.*)px,(.*)\)/, `translate3d($1,${newTranslateY}px,$3)`);
        });
      });
    }
  }
});

function handlePrev() {
  slider.value.prev();
  $event('roulette-prev');
}

function handleNext() {
  slider.value.next();
  $event('roulette-next');
}

const cardsList = [
  { character: 'angela', image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/angela.png', title: 'Talking Angela', color: 'pink' },
  { character: 'hank', image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/hank.png', title: 'Talking Hank', color: 'yellow' },
  { character: 'becca', image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/becca.png', title: 'Talking Becca', color: 'purple' },
  { character: 'ben', image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/ben.png', title: 'Talking Ben', color: 'green' },
  { character: 'ginger', image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/ginger.png', title: 'Talking Ginger', color: 'orange' },
  { character: 'tom', image: 'https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/tom.png', title: 'Talking Tom', color: 'blue' }
];

// If props.exclude is set, remove the card from the list
// Repeat the filtered cards so we reach a total of 30 cards
const cardsToUse = props.exclude ? cardsList.filter(card => card.character !== props.exclude) : cardsList;
let cards = cardsToUse;

do {
  if (cards.length > 30) cards.pop();
  else cards = cards.concat(cardsToUse);
} while (cards.length !== 30);
</script>

