<template>
  <div class="overflow-hidden">
    <div class="relative">
      <SvgShapeCrescent class="absolute top-[110vw] md:top-[25vw] w-[16vw] md:w-[8vw] text-turquoise -rotate-[60deg] right-[20vw] md:right-[45vw]"
                        :rotate="-45"
      />

      <SvgShapeDiamond class="absolute w-[13vw] md:w-[7vw] text-plum -left-[3vw] md:left-[-3vw] top-[13vw] md:top-[9vw]"
                       :rotate="155"
      />

      <SvgShapeCircle class="absolute h-[32vw] text-yellow right-[-14vw] md:right-[-4vw] top-[23vw] md:top-[-5vw] md:h-[17vw]" />

      <SvgShapeHalfCircle class="absolute w-[26vw] md:w-[13vw] text-lemon left-[16vw] -top-[36vw] md:top-[-12vw] md:left-[38vw] "
                          :rotate="120"
      />

      <SvgShapeArrowCurved class="hidden md:block absolute w-[13vw] md:w-[17vw] text-yellow left-[-2vw] top-[60vw] md:top-[50vw] rotate-[-53deg]" />
      <SvgShapeGearSharp class="absolute hidden md:block md:top-[45vw] w-[13vw] md:w-[21vw] text-lemon right-[-3vw]" />

      <SvgShapeArrowStraight class="absolute w-[30vw] md:w-[16vw] text-lemon left-[-9vw] md:left-[-2vw] bottom-[145vw] md:bottom-[42vw]" />

      <SvgShapeHalfCircle class="absolute w-[20vw] md:w-[10vw] text-purple right-[10vw] md:right-[5vw] bottom-[145vw] md:bottom-[47vw]"
                          :rotate="30"
      />

      <SvgShapeCrescent class="absolute bottom-[4vw] md:bottom-[17vw] w-[16vw] md:w-[8vw] text-turquoise -rotate-[60deg] left-[20vw] md:left-auto md:right-[8vw]"
                        :rotate="-70"
      />

      <SvgShapeGearSharp class="absolute hidden md:block md:bottom-[6vw] w-[13vw] md:w-[12vw] text-toxic left-[5vw]" />

      <div class="flex mt-[25vw] md:mt-[10vw] justify-center flex-wrap text-white">
        <div class="order-2 md:order-1 mt-[2.75vw] md:mt-0 relative z-10">
          <Motion :initial="{
                    x: calculateAnimationX(-8),
                    rotate: 0,
                  }"
                  :transition="{
                    duration: 1,
                    ease: 'ease-in-out'
                  }"
                  :in-view="{ x: 0, rotate: -4, }"
          >
            <CardCharacter title="Talking Angela"
                           character="angela"
                           image="https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/angela.png"
                           background-color="pink"
            />
          </Motion>
        </div>

        <h2 class="order-1 md:order-2 font-work font-extrabold leading-none text-[12vw] md:text-[6vw] text-center m-auto tracking-[-0.01em] md:mx-[2.75vw] min-w-full mb-[14vw] md:min-w-[37.5vw] text-indigo">
          <AnimateFadeIn>
            <span>Ready <br>to roll…</span>
          </AnimateFadeIn>
        </h2>

        <div class="order-3 md:order-3 mt-0 md:mt-[2.75vw] relative z-0">
          <Motion :initial="{
                    x: calculateAnimationX(8),
                    rotate: 0,
                  }"
                  :transition="{
                    duration: 1,
                    ease: 'ease-in-out'
                  }"
                  :in-view="{ x: 0, rotate: 4,}"
          >
            <CardCharacter title="Talking Tom"
                           background-color="blue"
                           character="tom"
                           image="https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/tom.png"
            />
          </Motion>
        </div>
      </div>

      <h2 class="order-1 md:order-2 font-work font-extrabold leading-none text-[12vw] md:text-[6vw] text-center m-auto tracking-[-0.01em] md:mx-[2.75vw] min-w-full md:min-w-fit mb-[14vw] mt-[40vw] md:mt-[18vw] md:mb-[3.2vw] text-indigo">
        <AnimateFadeIn>
          <span>with plenty <br>of surprises…</span>
        </AnimateFadeIn>
      </h2>

      <div class="flex mt-[12vw] md:mt-[10vw] justify-center flex-wrap text-white">
        <div class="order-1 mt-0 md:mt-[1.5vw]">
          <Motion :initial="{
                    x: calculateAnimationX(size.isMobile ? 16 : 26),
                    y: calculateAnimationX(size.isMobile ? 10 : 0),
                    rotate: 0,
                  }"
                  :transition="{
                    duration: 1,
                    ease: 'ease-in-out'
                  }"
                  :in-view="{ x: 0, y: 0, rotate: -5 }"
                  class="relative md:z-[1]"
          >
            <CardCharacter title="Talking Becca"
                           character="becca"
                           background-color="purple"
                           image="https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/becca.png"
            />
          </Motion>
        </div>
        <div class="order-2 mt-[16vw] md:mt-0 mr-[-10vw] md:mr-0">
          <Motion :initial="{
                    x: calculateAnimationX(size.isMobile ? -16 : 8),
                    rotate: 0
                  }"
                  :transition="{
                    duration: 1,
                    ease: 'ease-in-out'
                  }"
                  :in-view="{ x: 0, rotate: size.isMobile ? 4 : 2 }"
                  class="relative md:z-[2] z-[2]"
          >
            <CardCharacter title="Talking Ben"
                           character="ben"
                           background-color="green"
                           image="https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/ben.png"
                           class="relative"
            />
          </Motion>
        </div>
        <div class="order-3 mt-[-15vw] md:mt-[2.75vw]">
          <Motion :initial="{
                    x: calculateAnimationX(size.isMobile ? 16 : -9),
                    y: calculateAnimationX(size.isMobile ? -52 : 0),
                    rotate: 0
                  }"
                  :transition="{
                    duration: 1,
                    ease: 'ease-in-out'
                  }"
                  :in-view="{ x: 0, y: 0, rotate: size.isMobile ? 4 : -3 }"
                  class="relative md:z-[3]"
          >
            <CardCharacter title="Talking Ginger"
                           character="ginger"
                           background-color="orange"
                           image="https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/ginger.png"
            />
          </Motion>
        </div>
        <div class="order-4 mt-[-4vw] md:mt-[1.5vw]">
          <Motion :initial="{
                    x: calculateAnimationX(size.isMobile ? -16 : -27),
                    y: calculateAnimationX(size.isMobile ? -62 : 0),
                    rotate: 0
                  }"
                  :transition="{
                    duration: 1,
                    ease: 'ease-in-out'
                  }"
                  :in-view="{ x: 0, y: 0, rotate: size.isMobile ? -4 : 2 }"
                  class="relative md:z-[4]"
          >
            <CardCharacter title="Talking Hank"
                           character="hank"
                           background-color="yellow"
                           image="https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/hank.png"
            />
          </Motion>
        </div>
      </div>
      <SvgShapeCurve class="mt-[-20vw]" />
      <div class="pt-[25vw] pb-[30vw] md:pb-[12vw] md:pt-[5vw] justify-center bg-indigo -mt-px">
        <h2 class="order-1 md:order-2 font-work font-extrabold tracking-[-0.01em]
          leading-none text-[12vw] md:text-[6vw] text-center m-auto
          md:mx-[2.75vw] min-w-full md:min-w-fit mb-[4vw] text-white"
        >
          <AnimateFadeIn>
            <span>and full<br>of tricks!</span>
          </AnimateFadeIn>
        </h2>

        <div class="flex flex-wrap justify-center text-indigo">
          <div class="mt-[4vw] md:mt-0 md:mr-[5vw]">
            <Motion :initial="{
                      x: calculateAnimationX(12),
                      rotate: 0
                    }"
                    :transition="{
                      duration: 1,
                      ease: 'ease-in-out'
                    }"
                    :in-view="{ x: 0, rotate: -4 }"
            >
              <CardCharacter title="The Rakoonz"
                             background-color="green"
                             image="https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/roy.png"
              />
            </Motion>
          </div>
          <div class=" mt-[16vw] md:mt-[1.5vw] md:z-10 ">
            <Motion :initial="{
                      x: calculateAnimationX(-10),
                    }"
                    :transition="{
                      duration: 1,
                      ease: 'ease-in-out'
                    }"
                    :in-view="{ x: 0, }"
            >
              <CardCharacter title="The pets"
                             background-color="lemon"
                             image="https://cdn-ttf.o7web.com/assets/public/img/characters/roulette/pets.png"
                             class="rotate-[4deg]"
              />
            </Motion>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Motion } from '@motionone/vue/dist/motion-vue.ssr.js';
import { screenSize } from '~/composables/screen-size';

const size = screenSize();

const calculateAnimationX = (percent: number) => {
  return size.value.width / 100 * percent;
};
</script>
